import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Plot from 'react-plotly.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactGA from 'react-ga';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet';

//ReactGA.initialize('G-VSPXRF1XG8')
ReactGA.initialize('UA-144182217-3')


// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.


class BasicExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coolValue: 5,
      RESTcallTime: null,
      prices: [],
    };
  }

  someFunction = function() {
    console.log("in someFunction")
  }

  componentDidMount() {

    ReactGA.pageview(window.location.pathname);

    Promise.all([
      fetch('https://api.chainsigma.com/interval1ds/?format=json'),
      fetch('https://api.chainsigma.com/halvings/?format=json')
    ]).then(function (responses) {
      // Get a JSON object from each of the responses
      return Promise.all(responses.map(function (response) {
        return response.json();
      }));
    }).then((data) =>  {
      // Log the data to the console
      // You would do something with both sets of data here

      const prices_close = data[0].results.map(a => a.price_close)

      var halvingPricesY = []
      var halvingPricesX = []
      var halving_indices = data[1].results.map(a => a.index_corresponding_1di)
      halving_indices.push(prices_close.length)
      var previous_halving_index = halving_indices[0]
      //var maximum_length = 0

      var halving_chart_data = []

      for (var index of halving_indices.slice(1)) {
        var tmp_arr = prices_close.slice(previous_halving_index, index)
        console.log("len", tmp_arr.length)
        //maximum_length = Math.max(maximum_length, index - previous_halving_index)
        //halvingPricesX.push(Array.from(Array(index - previous_halving_index).keys()))
        //console.log(tmp_arr.length)
        //console.log(index - previous_halving_index)
        //halvingPricesY.push(tmp_arr)

        const firstPrice = tmp_arr[0]

        for (var i = 0; i < tmp_arr.length; i++) {
          tmp_arr[i] /= firstPrice;
        }

        var visible = true
        if(halving_chart_data.length == 0) {
          visible = "legendonly"
        }

        var title = "After 1st trade (genesis period)"
        if(halving_chart_data.length == 1) {
          title = "After 1st halving"
        }
        if(halving_chart_data.length == 2) {
          title = "After 2nd halving"
        }
        if(halving_chart_data.length == 3) {
          title = "After 3rd halving"
        }
        if(halving_chart_data.length >= 4) {
          title = "After " + halving_chart_data.length.toString() + "th halving"
        }

        var trace = {
          x: Array.from(Array(index - previous_halving_index).keys()),
          y: tmp_arr,
          type: 'scatter',
          visible: visible,
          name: title
        };

        halving_chart_data.push(trace)

        previous_halving_index = index
      }

      this.setState({ prices: prices_close, dates: data[0].results.map(a => a.time), allRelevantData: data[0].results, RESTcallTime: Date.now(), halving_chart_data: halving_chart_data})



      //console.log("data", data);
    }).catch(function (error) {
      // if there's an error, log it
      console.log(error);
    });





  }



  render() {
    const history = this.state;




    //console.log("Hi")

    let status;

  return (
    
    <Router>

    <Navbar bg="dark" variant="dark">
      <Navbar.Brand as={Link} to="/">chainsigma.com</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/">Home</Nav.Link>
          <Nav.Link as={Link} to="/price-history">Price history</Nav.Link>
          <Nav.Link as={Link} to="/halving-cycles">Halving cycles</Nav.Link>
          <Nav.Link as={Link} to="/dca-investing">DCA investing</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    
          <section class="features-icons bg-white text-center">
            
            {/*
              A <Switch> looks through all its children <Route>
              elements and renders the first one whose path
              matches the current URL. Use a <Switch> any time
              you have multiple routes, but you want only one
              of them to render at a time
            */}
            
            <Switch>
              <Route exact path="/">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
          </div>
          <div class="col-lg-4">


                <Home />



                </div>
          <div class="col-lg-4">
          </div>
        </div>
      </div>

              </Route>

              <Route path={["/price-history", "/bitcoin-price-history"]}>
                <PriceHistory
        value={this.state.prices}
        dates={this.state.dates}
        callTime={this.state.RESTcallTime} />
              </Route>

        <Route path={["/halving-cycles", "/bitcoin-halving-cycles"]}>
          <HalvingCycles
        halving_chart_data={this.state.halving_chart_data} />
        </Route>

<Route path={["/dca-investing", "/bitcoin-dca-investing"]}>
  <DcaInvesting
        value={this.state.prices}
        dates={this.state.dates}
        key={this.state.dates} />
</Route>

              <Route path="/dashboard">
                <Dashboard />
              </Route>

            </Switch>
             
    </section>
    
      <footer class="footer bg-light">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 h-100 text-center text-lg-left my-auto">
              <ul class="list-inline mb-2">
                
              </ul>
              <p class="text-muted small mb-4 mb-lg-0">The contents of this website are not financial advice.<br/>&copy; chainsigma.com 2021.</p>
            </div>
            <div class="col-lg-6 h-100 text-center text-lg-right my-auto">
              <ul class="list-inline mb-0">
    
                <li class="list-inline-item mr-3">
                  <a href="https://twitter.com/chainsigma">
                   <FontAwesomeIcon icon={faTwitterSquare} size="2x" />
                  </a>
                </li>
    
              </ul>
            </div>
          </div>
        </div>
      </footer>
    
        </Router>
  );
}



}

// You can think of these components as "pages"
// in your app.

function Home() {
  return (
    <div> 
    <Helmet>
      <title>ChainSigma | Home</title>
    </Helmet>
        
        <p class="h1">Welcome to chainsigma.com!</p>
          <p class="lead">We live in exciting times of Bitcoin price discovery. This website educates you about the main drivers of the Bitcoin price, and how you can use Bitcoin for your investments.</p>
          <p class="lead mb-0">Specifically, you find out more about Bitcoin's past price development, the impact of halvings on the price, and the past performance of dollar cost averaging based Bitcoin investing. Navigate at the top to compute real time statistics!</p>
      
    </div>
  );
}

function PriceHistory(props) {
  console.log("ha", "ho", props)
  return (
    <div>
    <Helmet>
      <title>ChainSigma | Price history</title>
    </Helmet>

      <Plot
   style={{ width: '98%', height: '100%' }}
        data={[
          {
            x: props.dates,//Array.from(Array(props.value.length).keys()),
            y: props.value,
            type: 'scatter',
            mode: 'lines',
            marker: {color: 'red'},
          },
        ]}
        layout={ {
        yaxis: {
          autorange: true,
          title: 'Bitcoin price [USD]'
        },
        xaxis: {
          autorange: true,
          title: 'Time'
        },
      } }
        
      />

<p class="lead">We put in much effort to obtain the early prices! The bitcoin network started running on January 3rd 2009, but the first trade occured more than 9 months later: on October 12th, Martti Malmi, a Finnish developer that helped Satoshi work on Bitcoin, bought the first Bitcoin. The first trade on an exchange was another 9 months later on July 17th, 2010, on MtGox.</p>
      
    </div>
  );
}

function HalvingCycles(props) {
  console.log("ha", "ho", props)
  return (
    <div>
    <Helmet>
      <title>ChainSigma | Halving cycles</title>
    </Helmet>

      <Plot
   style={{ width: '98%', height: '100%' }}
        data={props.halving_chart_data}
        layout={ {
        yaxis: {
          type: 'log',
          autorange: true,
          title: 'Bitcoin price growth'
        },
        xaxis: {
          autorange: true,
          title: 'Time since halving [days]'
        },
      } }
        
      />

<p class="lead">When a block is mined roughly every 10 minutes, the miners collect a block reward. At the moment, the block reward is 6.25 BTC. </p>
<p class="lead">Every 210,000 blocks, or roughly every 4 years, an event known as the halving occurs: the block reward gets cut in half. The last halving occured on May 11th, 2020, meaning that miners before were able to get a block reward of 12.5 BTC.</p>
<p class="lead">As miners typically sell at least part of their mined Bitcoin (e.g., to cover their equipment and maintenance cost), the supply of new Bitcoins traded on the market decreases. At the same time, the demand for Bitcoin has increased in the past. Following the halving events, a supply shock typically occurs in the months after, driving the price of Bitcoin to much higher levels than seen before.</p>
<p class="lead">Additionally, we can also look at the time after the first Bitcoin block on January 3rd 2009. This event was not a halving, but the genesis of the first block, when the initial block reward was set to 50 Bitcoin.</p>
      
    </div>
  );
}

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
}


  
class DcaInvesting extends React.Component {

  printState() {
    console.log(this.state.xData)
  }
  
  drawChart() {
    var priceArLen = 0
    if(Array.isArray(this.state.priceDates)) {
      priceArLen = this.state.priceDates.length
    }
    console.log('drawing chart, dateSelected', this.state.dateSelected, "length of prices", priceArLen);
    if(Array.isArray(this.state.priceDates) && this.state.priceDates.length) {
      //console.log("first date, selected date", Date.parse(this.state.priceDates[0]), this.state.dateSelected)
      //console.log(typeof Date.parse(this.state.priceDates[0]))
      var ind = 0
      var dateArray = []
      var accumulatedAmount = []
      var accumulatedWorth = []

      for (var date of this.state.priceDates) {
        if(Date.parse(date) >= this.state.dateSelected) {
          console.log("found good date")
          dateArray.push(date)
          var accumulatedAmountBefore = 0
          if(accumulatedAmount.length > 0) {
            accumulatedAmountBefore = accumulatedAmount[accumulatedAmount.length - 1]
          }
          accumulatedAmountBefore += this.state.dailyAmount / this.state.priceValues[ind]
          accumulatedAmount.push(accumulatedAmountBefore)
          accumulatedWorth.push(this.state.priceValues[ind] * accumulatedAmountBefore)
        }
        ind += 1
      }

      //this.state.xData = dateArray
      //this.state.yData = accumulatedWorth
      this.setState({xData: dateArray, yData: accumulatedWorth});
      
      //console.log(this.state.xData)
      //console.log(this.state.yData)
      //console.log(accumulatedAmount)
    }
    //console.log(this.state.dateSelected)
    //
  }

  constructor(props) {
    super(props);
    console.log("initially setting state")
    this.state = {dailyAmount: 1, dateSelected: Date.parse("01 Jan 2021"), 
      priceValues: props.value,
      priceDates: props.dates,
      //xData: Array.from(Array(1000).keys()),
      //yData: Array.from(Array(1000).keys()),
    };

    this.handleDateSelect = this.handleDateSelect.bind(this);
    this.handleTextBox = this.handleTextBox.bind(this);
    this.printState = this.printState.bind(this);
    this.drawChart = this.drawChart.bind(this);

    this.drawChart()
  }

  handleDateSelect(event) {
    //console.log('You clicked submit.');
    this.setState({dateSelected: event}, this.drawChart);
    console.log(event);
    console.log("selected date, new date in state", this.state.dateSelected)
    //this.drawChart();
  }
  
  handleTextBox(event) {
    //console.log('You entered text.');
    console.log(event);
    this.setState({dailyAmount: event.target.value}, this.drawChart);
    //this.drawChart();
  }

  render() {

    return (
      <div>
      <Helmet>
        <title>ChainSigma | DCA investing</title>
      </Helmet>
        <div class="container">
  <div class="row">
    <div class="col-sm">
      Enter the values:
    </div>
    <div class="col-sm">
    Daily investment [USD]
    </div>
    <div class="col-sm">

<input type="text" name="name"
value={this.state.dailyAmount}
onChange={this.handleTextBox} />

    </div>
  </div>
  
  <div class="row">
    <div class="col-sm">
    </div>
    <div class="col-sm">
    First investment
    </div>
    <div class="col-sm">

    <DatePicker
  selected={this.state.dateSelected}
  onChange={this.handleDateSelect} //when day is clicked
  minDate={new Date("11-10-2009")} // todo aus erster Tag von anderer API nehmen
  maxDate={Date.now()}
/>

    </div>
  </div>

</div>

        <div className="board-row">



        <br />




          




          <Plot
   style={{ width: '98%', height: '100%' }}

   data={
		[{
            x: this.state.xData,//Array.from(Array(props.value.length).keys()),
            y: this.state.yData,
            type: 'scatter',
            mode: 'lines',
            marker: {color: 'red'},
          },]
		}
        layout={ {
        yaxis: {
          autorange: true,
          title: 'Bitcoin portfolio worth [USD]'
        },
        xaxis: {
          autorange: true,
          title: 'Time'
        },
      } }
        
      />


        </div>
      </div>
    );
  }
}

export default BasicExample;